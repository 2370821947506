import _ from "lodash";
import React from "react";
import { 
  Grid, 
  Select, 
  TextField
} from "@material-ui/core";
import { isSelectionNatureStrict } from 'utils/utils';

function buildConditions(totalOptions, optionsDisplayConditions) {
  const conditions = new Array(totalOptions);
    
  for(let i = 0; i < totalOptions; i++) {
    // likertAndIs is used by Likert surveys
    conditions[i] = [{ type: 'question', negative: false, value: { questionId: null, value: null, check: 'equals', likertAndIs: [{ i: null, v: null }] }  }];
  }

  optionsDisplayConditions.forEach((c, idx) => { conditions[idx] = c });

  return conditions;
}

// { type: 'or', value: [
//    {type: 'question', negative: false, value: { questionId: 'abcd', value: '0', check: 'equals' }},
// ]}

class Options extends React.Component {
  constructor(props) {
    super(props);

    const { question } = this.props;

    let optionsDisplayConditions = [];
    if (question && question.optionsDisplayConditions) {
      optionsDisplayConditions = question.optionsDisplayConditions.map(
        (c) => c.value
      );
    }

    this.conditions = buildConditions(
      question.options.length,
      optionsDisplayConditions
    );
  }

  questionOptions() {
    const currentQuestionIndex = _.findIndex(this.props.questions, {
      questionId: this.props.question.questionId,
    });

    return this.props.questions.map((q, idx) => {
      if (
        !isSelectionNatureStrict(q.type) ||
        q.questionId === this.props.question.questionId ||
        idx > currentQuestionIndex
      )
        return null;

      return (
        <option key={`QUESTION-${q.questionId}`} value={q.questionId}>
          {`Q${idx + 1}. ${q.text}`}
        </option>
      );
    });
  }

  updateCondition(idx, updates) {
    const qInfo = _.cloneDeep(this.props.question);
    this.conditions = _.cloneDeep(this.conditions);

    this.conditions[idx][0]['value'] = {
      ...this.conditions[idx][0]['value'],
      ...updates,
    };
    qInfo.optionsDisplayConditions = this.conditions.map((c) => ({
      type: 'or',
      value: c,
    }));
    this.props.modFunctions.changeQInfo(
      qInfo,
      this.props.question.questionId,
      true
    );
  }
  
  options(idx) {
    const conditions = this.conditions[idx];

    if (!conditions || conditions.length === 0) return [];

    const questionId = conditions[0].value.questionId;
    const questionIds = this.props.questions.map((q) => q.questionId);
    const questionIdx = questionIds.indexOf(questionId);

    if (questionIdx === -1) return [];

    const question = this.props.questions[questionIdx];

    const opts = [];

    opts.push(<option value="">None</option>);

    question.options.forEach((option, i) =>
      opts.push(
        <option key={`OPTION-${questionId}-${i}`} value={`${i}`}>
          {`Q${questionIdx + 1}. Option ${i + 1} - ${option.text}`}
        </option>
      )
    );
    return opts;
  }

  // copied from likert.js
  defaultLikertOptions() {
    return {
      isCustom: false,
      bounds: {
        lower: { value: 1, label: '' },
        upper: { value: 5, label: '' },
        interval: 1,
      },
      custom: [
        { value: '1', label: 'One' },
        { value: '2', label: 'Two' },
        { value: '3', label: 'Three' },
        { value: '4', label: 'Four' },
        { value: '5', label: 'Five' },
      ],
    };
  }

  answers(idx) {
    const conditions = this.conditions[idx];

    if (!conditions || conditions.length === 0) return [];

    const questionId = conditions[0].value.questionId;
    const questionIds = this.props.questions.map((q) => q.questionId);
    const questionIdx = questionIds.indexOf(questionId);

    if (questionIdx === -1) return [];

    const question = this.props.questions[questionIdx];
    if (question.type !== 'LIKERT') return [];

    const opts = [];
    opts.push(<option value="">None</option>);

    // for custom im always using idx 0 because its the only one that has the full custom object/options for whatever reason
    // if not custom just use default likert options
    const options = question.optionsMatrix.inputs[0]?.custom ?? this.defaultLikertOptions().custom;

    options.forEach((option, i) =>
      opts.push(
        <option
          key={`OPTION-${questionId}-LAB-${option.label}-VAL-${option.value}`}
          value={`${option.value}`}
          likertChoice={`${option.label}`}
        >
          {`Q${questionIdx + 1}. Option ${i + 1} - ${option.label}`}
        </option>
      )
    );
    return opts;
  }

  // named it after Likert because right now this is the only type that has this additional layer
  buildLikertHTML(idx) {
    const options = this.answers(idx);

    return _.isEmpty(options) ? null : (
      <Grid item xs={5}>
        <label>And Is</label>
        <Select
          variant="outlined"
          margin="dense"
          className="option-fullwidth"
          native={true}
          multiple
          value={_.get(this.conditions, `[${idx}][0].value.likertAndIs`, []).map(answer => answer.v) || []}
          onChange={(e) => {
            const { options } = e.target;
            const selectedOptions = _.filter(options, ({ selected }) => selected);

            if (_.isEmpty(selectedOptions)) return;
            
            const selectedAnswers = _.map(selectedOptions, opt => ({
              i: opt.getAttribute("likertChoice"),
              v: opt.value
            }));
            console.log("🚀 ~ Options ~ buildLikertHTML ~ selectedAnswers:", selectedAnswers)
            this.updateCondition(idx, { likertAndIs: selectedAnswers });
          }}
        >
          {options}
        </Select>
      </Grid>
    );
            
  }

  render() {
    if (!this.props.question) return null;

    return (
      <div style={{ marginBottom: 20 }}>
        {this.props.question.options.map((option, idx) => {
          return (
            <Grid key={`LOGIC-DISPLAY-OPTION-${idx}`} container spacing={3}>
              <Grid item xs={5}>
                <label>Option</label>
                <TextField
                  variant="filled"
                  value={option.text}
                  disabled
                  margin="dense"
                  style={{
                    width: '100%',
                    margin: 0,
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <label>Question</label>
                <Select
                  variant="outlined"
                  margin="dense"
                  className="option-fullwidth"
                  native={true}
                  value={this.conditions[idx][0].value.questionId}
                  onChange={(e) => {
                    const val = e.target.value === '' ? null : e.target.value;
                    this.updateCondition(idx, { questionId: val, value: null });
                  }}
                >
                  <option value="">None</option>
                  {this.questionOptions()}
                </Select>
              </Grid>
              <Grid item xs={5}>
                <label>Is</label>
                <Select
                  variant="outlined"
                  margin="dense"
                  className="option-fullwidth"
                  native={true}
                  value={this.conditions[idx][0].value.value}
                  onChange={(e) => {
                    const val = e.target.value === '' ? null : e.target.value;
                    this.updateCondition(idx, { value: val });
                  }}
                >
                  {this.options(idx)}
                </Select>
              </Grid>

              {this.buildLikertHTML(idx)}
            </Grid>
          );
        })}
      </div>
    );
  }
}

export default Options